<template>
  <el-form
    size="small"
    ref="editForm"
    label-position="top"
    :model="scale"
  >

    <el-form-item label="Диапазоны целевых значений, %">
      <el-row type="flex" justify="space-between">
        <el-col :span="7">
          <el-form-item prop="target_a">
            <el-input type="number" v-model="scale.target_a" :disabled="disabled">
              <template slot="prepend">A</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item prop="target_b">
            <el-input type="number" v-model="scale.target_b" :disabled="disabled">
              <template slot="prepend">B</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item prop="target_c">
            <el-input type="number" v-model="scale.target_c" :disabled="disabled">
              <template slot="prepend">C</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item
      label="Значение коэффициента при достижении целевых значений"
    >
      <el-row type="flex" justify="space-between">
        <el-col :span="7">
          <el-form-item prop="pay_a">
            <el-input type="number" v-model="scale.pay_a" :disabled="disabled">
              <template slot="prepend">A</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item prop="pay_b">
            <el-input type="number" v-model="scale.pay_b" :disabled="disabled">
              <template slot="prepend">B</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item prop="pay_c">
            <el-input type="number" v-model="scale.pay_c" :disabled="disabled">
              <template slot="prepend">C</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item
      label="Факт"
    >
      <el-input type="number" v-model="scale.fact" :disabled="disabled">
        <template slot="prepend">Факт</template>
      </el-input>
    </el-form-item>
  </el-form>
</template>

<script>

import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";
import {mapGetters} from "vuex";

export default {
  name: "library-edit-form",
  mixins: [dynamicScaleComponent],
  components: {},

  props: {},

  computed: {
    ...mapGetters('settings', {
      settings: 'settings',
    }),
  },



  mounted() {
    this.$nextTick(function () {
      this.$refs.editForm.clearValidate();
    })
  },

  data() {
    return {
      scale: {
        target_a: null,
        target_b: null,
        target_c: null,
        pay_a: null,
        pay_b: null,
        pay_c: null,
        fact: null,
      },
    }
  },

  methods: {}
}
</script>

<style>

</style>